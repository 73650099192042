/*

 Custom styles for Neodent organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

.container__header-provider {
    background: #853275 !important;
}

.login--form-logo-image {
    width: 185px !important;
}

.login--logo,
.header--logo,
.header-provider--logo {
    max-width: 125px !important;
}
.header-provider--logo {
    content: none !important;
    mask: url('../../images/custom_organization/logo.svg');
    mask-size: cover;
    background-color: white;
    height: 34px;
    width: 120px;
    margin-top: 15px;
}
.carousel--color .carousel--item {
    position: relative;

    &::before {
        content: '';
        background-color: rgba($color: #000000, $alpha: 0.3);
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

/*

 Custom colors for Neodent organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #853275;
$organization_primary_focus: #731978;
$organization_primary_disabled: #531059;

$organization_program_default: #853275;

$organization_secondary: #776EAD;
$organization_secondary_focus: #645F96;
$organization_secondary_transparent: #645F96;
$organization_secundary_border: #645F96;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #d1d1e7;

$organization_secundary_button: #776EAD;
$organization_secundary_button_focus: #645F96;
